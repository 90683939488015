#PropertyPanel {

  .no-icon {
    color: #757575;
    font-size: 12px;
  }

  .icon {
    width: 30px;
    height: 21px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.answer {
      background-image: url("../../../../../images/t_icon/answer.svg");
    }
    &.content {
      background-image: url("../../../../../images/t_icon/content.svg");
    }
    &.content_common {
      background-image: url("../../../../../images/t_icon/content_common.svg");
    }
    &.external_link {
      background-image: url("../../../../../images/t_icon/external_link.svg");
    }
    &.external_link_common {
      background-image: url("../../../../../images/t_icon/external_link_common.svg");
    }
    &.flashcards {
      background-image: url("../../../../../images/t_icon/flashcards.svg");
    }
    &.eigo.flashcards {
      background-image: url("../../../../../images/t_icon/flashcards_eigo.svg");
    }
    &.flashcards_common {
      background-image: url("../../../../../images/t_icon/flashcards_common_eigo.svg");
    }
    &.guide {
      background-image: url("../../../../../images/t_icon/guide.svg");
    }
    &.movie {
      background-image: url("../../../../../images/t_icon/movie.svg");
    }
    &.movie_common {
      background-image: url("../../../../../images/t_icon/movie_common.svg");
    }
    &.page_link {
      background-image: url("../../../../../images/t_icon/page_link.svg");
    }
    &.reading_sound {
      background-image: url("../../../../../images/t_icon/reading_sound.svg");
    }
    &.reading_sound_common {
      background-image: url("../../../../../images/t_icon/reading_sound_common.svg");
    }
    &.related_document {
      background-image: url("../../../../../images/t_icon/related_document.svg");
    }
    &.related_document_common {
      background-image: url("../../../../../images/t_icon/related_document_common.svg");
    }
    &.simulation {
      background-image: url("../../../../../images/t_icon/simulation.svg");
    }
    &.simulation_common {
      background-image: url("../../../../../images/t_icon/simulation_common.svg");
    }
    &.slideshow {
      background-image: url("../../../../../images/t_icon/slideshow.svg");
    }
    &.voice_common {
      background-image: url("../../../../../images/t_icon/voice_common.svg");
    }
    &.work_sheet {
      background-image: url("../../../../../images/t_icon/work_sheet.svg");
    }
    &.work_sheet_common {
      background-image: url("../../../../../images/t_icon/work_sheet_common.svg");
    }
    &.looking_back {
      background-image: url("../../../../../images/t_icon/looking_back.svg");
    }
    &.map {
      background-image: url("../../../../../images/t_icon/map.svg");
    }
    &.move_left {
      background-image: url("../../../../../images/t_icon/move_left.png");
    }
    &.move_right {
      background-image: url("../../../../../images/t_icon/move_right.png");
    }

    &.cont {
      background-image: url("../../../../../images/t_icon/cont.svg");
    }
    &.cont_qr {
      background-image: url("../../../../../images/t_icon/cont_qr.svg");
    }
    &.furika {
      background-image: url("../../../../../images/t_icon/furika.svg");
    }
    &.kaito {
      background-image: url("../../../../../images/t_icon/kaito.svg");
    }
    &.link {
      background-image: url("../../../../../images/t_icon/link.svg");
    }
    &.link_qr {
      background-image: url("../../../../../images/t_icon/link_qr.svg");
    }
    &.map_red {
      background-image: url("../../../../../images/t_icon/map_red.svg");
    }
    &.movie_qr {
      background-image: url("../../../../../images/t_icon/movie_qr.svg");
    }
    &.pd {
      background-image: url("../../../../../images/t_icon/PD.svg");
    }
    &.pd_student {
      background-image: url("../../../../../images/t_icon/PD_student.svg");
    }
    &.rensyu {
      background-image: url("../../../../../images/t_icon/rensyu.svg");
    }
    &.rensyu_qr {
      background-image: url("../../../../../images/t_icon/rensyu_qr.svg");
    }
    &.sheet {
      background-image: url("../../../../../images/t_icon/sheet.svg");
    }
    &.sheet_qr {
      background-image: url("../../../../../images/t_icon/sheet_qr.svg");
    }
    &.slide {
      background-image: url("../../../../../images/t_icon/slide.svg");
    }
    &.slide_qr {
      background-image: url("../../../../../images/t_icon/slide_qr.svg");
    }
    &.sim {
      background-image: url("../../../../../images/t_icon/sim.svg");
    }
    &.siryou {
      background-image: url("../../../../../images/t_icon/siryou.svg");
    }
    &.siryou_qr {
      background-image: url("../../../../../images/t_icon/siryou_qr.svg");
    }
    &.sound {
      background-image: url("../../../../../images/t_icon/sound.svg");
    }
    &.sound_qr {
      background-image: url("../../../../../images/t_icon/sound_qr.svg");
    }
    &.text {
      background-image: url("../../../../../images/t_icon/text.svg");
    }
    &.toujo {
      background-image: url("../../../../../images/t_icon/toujo.svg");
    }
    &.map_r6 {
      background-image: url("../../../../../images/t_icon/map_r6.svg");
    }
    &.movie_r6 {
      background-image: url("../../../../../images/t_icon/movie_r6.svg");
    }
    &.arasuji {
      background-image: url("../../../../../images/t_icon/arasuji.png");
    }
    &.mondai_jitsu {
      background-image: url("../../../../../images/t_icon/mondai_jitsu.png");
    }
    &.mondai_kiso {
      background-image: url("../../../../../images/t_icon/mondai_kiso.png");
    }
    &.movie_anime {
      background-image: url("../../../../../images/t_icon/movie_anime.png");
    }
    &.shidousha_digital {
      background-image: url("../../../../../images/t_icon/shidousha_digital.png");
    }
    &.sim_student {
      background-image: url("../../../../../images/t_icon/sim_student.png");
    }
  }

  .mask-color-select-contents {
    width: 60px;
    height: 15px;
    color: #CCCCCC;

    .mask-color-select-text {
      font-size: 10px;
    }
  }

  .color-sample {
    width: 10px;
    height: 10px;
    float: right;
    margin: 5px 0 0 0;
  }

  td.table-value {
    text-align: left;
    padding-left: 2%;
  }

  input[type=text],
  input[type=number] {
    width: 75%;
    padding: 4px 3px 4px 5px;
    background-color: #454545;
    border: 1px solid #676767;
    text-align: left;
    color: #e2e2e2;
    font-size: 12px;

    cursor: pointer;
  }

  textarea {
    resize: none;
    width: 100%;
    height: 60px;

    &.popupEditTextBox {
      color: #e2e2e2;
      background-color: #454545;
      border: 1px solid #676767;
      height: 100px;
      outline: none;
    }
  }

  /*************  check css

  input[type=checkbox]{
     position: relative;
     margin:0px 0 6px 0;
     cursor: pointer;
   }

   input[type=checkbox]:before {
     position: absolute;
     z-index: 1;
     width: 0.55rem;
     height: 0.42rem;
     content: '';
     -webkit-transform: rotate(-45deg) scale(0, 0);
         transform: rotate(-45deg) scale(0, 0);
     border: 2px solid #ffffff;
     border-top-style: none;
     border-right-style: none;

     margin:3px 0 0 4px;
   }

   input[type=checkbox]:checked:before {
     -webkit-transform: rotate(-45deg) scale(1, 1);
     transform: rotate(-45deg) scale(1, 1);
   }

   input[type=checkbox]:after {
     position: absolute;
     width: 18px;
     height: 18px;
     content: '';
     cursor: pointer;
     background-color: #454545;
     border: 1px solid #676767;
   }
   */

  /********************* check box switch start ********************/

  .switch_label {
    width: 34px;
    position: relative;
    display: inline-block;
    padding-top: 5px;
  }

  .switch_content {
    display: block;
    cursor: pointer;
    position: relative;
    border-radius: 7px;
    height: 14px;

    background-color: #454545;
    -webkit-transition: all .1s .4s;
    -moz-transition: all .1s .4s;
    -ms-transition: all .1s .4s;
    -o-transition: all .1s .4s;
    transition: all .1s .4s;
    overflow: hidden;
  }

  .switch_content:after {
    content: "";
    display: block;
    position: absolute;

    width: 0;
    height: 100%;
    top: 0;
    left: 0;

    border-radius: 7px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }

  .switch_input {
    display: none;
  }

  .switch_circle {
    display: block;
    top: 5px;
    left: 0px;
    position: absolute;
    width: 14px;
    height: 14px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-color: #7f7f7f;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
  }

  .switch_input:checked ~ .switch_circle {
    left: 21px;
    background-color: #ffffff;
  }

  .switch_input:checked ~ .switch_content {

    border-color: transparent;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -ms-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
  }

  .switch_input:checked ~ .switch_content:after {
    background-color: #46a0ef;
    width: 100%;
  }

  /********************* check box switch end ********************/


  select {
    width: 84%;
    padding: 3px 7px 5px 2px;
    background-color: #454545;
    border: 1px solid #676767;
    text-align: left;
    color: #cdcdcd;
    font-size: 0.75rem;

    line-height: 1.8;
    cursor: pointer;

    outline: none;
  }

  .checkbox {
    margin: 3px 0;
    background-color: #454545;
    border: 1px solid #676767;
    width: 1.2em;
    height: 1.2em;
  }

  .unit {
    display: inline-block;
    padding-left: 3px;
    color: #9b9b9b;
    font-size: 0.7rem;
    font-family: Meiryo, 'Hiragino Kaku Gothic ProN', sans-serif;
  }


  .btnlink_save {
    color: #e3e3e3;
    border-radius: 4px;
    border: 1px solid #828282;
    padding: 5px 12px;
    background-color: #4b4b4b;
    font-size: 0.75rem;
    cursor: pointer;

    &:hover, .selected {
      color: #fafafa;
      border: 1px solid #8a8a8a;
      background-color: #8a8a8a;
    }
  }

  .panel-property-inner .inner-mask {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    top: 35px;
    left: 0;
    background-color: rgba(0, 0, 0, .0);
    padding: 0;
  }

  .panel-property-inner .inner-table {
    padding: 10px 10px 2px 10px;
    /*border-bottom: 1px solid #474747;*/
    margin-bottom: 10px;
  }

  .panel-property-inner .inner-table table, th, td {
    width: 100%;
    border-collapse: collapse;
    border: 0 solid black;
  }

  .table-key {
    width: 45%;
    line-height: 2.1;
    font-size: 12px;
    text-align: left;
  }

  .table-question {
    width: 5%;
  }

  .table-value {
    width: 50%;
    text-align: left;
    font-size: 7px;
    padding: 2px 0;
  }

  .svg-preset-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2px;
  }
  .svg-preset-btn {
    height: 25px;
    border-radius: 4px;
    border: 1px solid #8a8a8a;
    background-color: #747475;

    svg {
      margin: 10%;

      polygon {
        fill-opacity: 0.2;
        stroke: #fafafa;
        stroke-width: 1px;
        vector-effect: non-scaling-stroke;
      }
    }
    &:hover {
      background-color: #909091;
      cursor: pointer;
    }
  }

  .upload-btn {
    width: 72%;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 11px;
    text-align: center;
    background-color: #444444;
    border-radius: 5px;
    padding: 3px 6px 1px 6px;
    border: 1px solid #676767;
    margin: 1px 0;

    &:hover {
      background-color: #383838;
    }

    &:active {
      background-color: #343434;
    }
  }

  .text_change_btn_td {
    padding-right: 20px;
  }

  .btnlink_box{
    width:62px;

    font-size: 0.75rem;
    color: #e3e3e3;

    padding: 4px 0px;
    margin:0 2px;

    background-color: #4b4b4b;
    border-radius: 4px;
    border: 1px solid #828282;

    cursor: pointer;
    float: right;
    outline: none;

    &:hover, selected{
      color: #fafafa;
      border: 1px solid #8a8a8a;
      background-color: #8a8a8a;
    }
  }

  .box_rightspace{
    margin-right:5px;
  }

  .small_width_btn {
    width: 31px;
  }
}
