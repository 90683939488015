#GuideModePanel {
  .group-list {
    width: 94%;
    margin: 3%;
    height: 316px;
    background-color: #ffffff;
    overflow: auto;
    outline: none;
    border: 1px solid #4b4b4b;
    border-radius: 2px;

    .no-group-list {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .btn-container {
    width: 90%;
    margin: 1% 5%
  }

  .btn-gray {
    font-size: 0.75rem;
    color: #e3e3e3;
    padding: 4px 7px;
    margin: 0 2px;
    background-color: #4b4b4b;
    border-radius: 4px;
    border: 1px solid #828282;
    cursor: pointer;
    outline: none;
    display: inline-block;

    &.add-btn{
      float: left;
    }
    &.save-btn,
    &.cancel-btn {
      float: right;
    }

    &:hover {
      color: #fafafa;
      border: 1px solid #8a8a8a;
      background-color: #8a8a8a;
    }
  }

  .group-edit-container {

    .group-header {
      width: 100%;
      height: 25px;

      .group-toggle {
        display: inline-block;
        float: left;
        width: 10%;
        height: 100%;
        line-height: 25px;
        color: #585858;

        &.toggled {
          background-color: burlywood;
        }
      }

      .group-name {
        width: 80%;
        height: 100%;
        display: inline-flex;
        justify-content: center;

        input {
          width: 96%;
          padding: 2%;
        }
      }

      .group-delete-x {
        position: relative;
        display: inline-block;
        float: right;
        width: 10%;
        height: 100%;
        line-height: 25px;
        color: #585858;
      }
    }

    .group-contents {
      .drop-zone {
        min-height: 40px;
        padding: 1px 0;
        border-radius: 5px;
        margin: 1px 5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 12px;

        &.zone-empty {
          border: 2px dotted #8c8c8c;
        }
        &.zone-dragging {
          border: 2px dotted #177cee;
        }
        &.zone-hovered {
          border: 2px dotted #c8c916;
        }
      }
      .group-item {
        text-align: left;
        border-radius: 5px;
        cursor: n-resize;
        margin: 2%;
        height: 17px;
        background-color: #535353;
        display: block;
        width: 85%;
        padding: 1px 6px;
        border: 1px solid #676767;

        &:hover {
          background-color: #474747;
        }

        .delete-group-item {
          float: right;
          cursor: pointer;
          margin-right: 4px;
        }
      }
    }
  }
}