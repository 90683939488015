.admin-component-area {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #212224;
  display: block;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.admin-content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:90px;


  .admin-content-center {
    width: 50%;
    height: auto;
    margin: 0 auto;
    background-color: #323232;
    border-radius: 3px;
    border: 1px solid #3d3d3d;
    padding: 30px 50px 40px 50px;
  }

  .user-list-title {
    color: #FFFFFF;
  }

}