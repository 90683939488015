.file-info-area {
    width: 75%;
    height: 100%;
    background-color: #565656;
    border: 1px solid #767676;
    border-radius: 3px;
    color: #FFFFFF;
    padding: 15px;
    display: inline-block;


    .all-file-info {
        width: 35%;
        display: inline-block;
        margin: 0 30px;

        .file-info-value {
            float: right;
            color: #A5A5A5;
        }
    }

    .clear-file-info {
        width: 40%;
        display: inline-block;
        margin: 0 30px;

        .file-info-value {
            float: right;
            color: #A5A5A5;
        }
    }

    .file-btn {
        background-color: #474747;
        margin: 0 2px;
    }
}