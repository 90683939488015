#Editor {
  width: inherit;
  height: inherit;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  #BackgroundLayer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #ffffff;
    pointer-events: none;

    .background-half {
      display: inline-block;

      .left-bg {
        height: 100%;
        width: 100%;
        max-width: 100%;
        float: right;
      }

      .right-bg {
        height: 100%;
        width: 100%;
        max-width: 100%;
        float: left;
      }
    }
  }

  #DragLayer {
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;

    .drag-item-id {
      font-size: 20px;
      font-weight: bold;
      overflow: hidden;
      word-break: break-all;
      color: white;
      text-shadow: 2px 2px 2px gray;
    }

    .draggable-box {
      position: absolute;
      cursor: grab;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(23, 125, 239, .5);

      &.dragging {
        background-color: rgba(23, 125, 239, .3);
      }
    }
  }

  #DrawLayer {
    width: 100%;
    height: 100%;

    z-index: 99;
    position: absolute;
    background-color: rgba(0, 0, 0, .6);
    cursor: crosshair;

    .crop-area {
      position: absolute;
      background-color: rgba(107, 206, 255, .5);
      border: 1px solid #333333;
      outline: 1px solid #ffffff;
      pointer-events: none;
    }
  }

  #ObjectLayer {
    width: 100%;
    height: 100%;
    position: absolute;

    .object-layer-left,
    .object-layer-right {
      float: left;
      width: 50%;
      height: 100%;
    }

    .editor-panel-container {
      width: calc(100% - 40px);
      height: calc(100vh - 40px);
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .imageUpload {
      background-image: url(../../../images/no-image.jpg);
      background-size: inherit;
      position: absolute;
      border-right: 1px solid #000000;
    }

    .imageUpload input[type="file"] {
      cursor: pointer;
    }

    .leftHideLayer {
      position: absolute;
      width: 50%;
      height: 100%;
      background-color: rgba(35, 35, 35, 0.5);
    }

    .rightHideLayer {
      position: absolute;
      width: 50%;
      height: 100%;
      left: 50%;
      background-color: rgba(35, 35, 35, 0.5);
    }
  }

  #MyEditorLayer {
    width: 100%;
    height: 100%;
    position: absolute;

    .object-layer-left,
    .object-layer-right {
      float: left;
      width: 50%;
      height: 100%;
    }

    .editor-panel-container {
      width: calc(100% - 40px);
      height: calc(100vh - 40px);
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .leftHideLayer {
      position: absolute;
      width: 50%;
      height: 100%;
      background-color: rgba(35, 35, 35, 0.5);
    }

    .rightHideLayer {
      position: absolute;
      width: 50%;
      height: 100%;
      left: 50%;
      background-color: rgba(35, 35, 35, 0.5);
    }
  }

  #SVGEditLayer {
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;

    .selected-object {
      position: absolute;

      .svg-points {
        position: absolute;
        width: 1px;
        height: 1px;

        cursor: grab;

        &:after {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          top: -3px;
          left: -3px;
          outline: 2px solid black;
          background-color: inherit;
        }

        &.react-draggable-dragging {
          cursor: grabbing;
        }
      }
    }
  }

  #EditorNavigation {
    position: relative;
    pointer-events: none;
    z-index: 100;

    .left-btn-container,
    .right-btn-container {
      cursor: pointer;
      display: inline-block;
      font-size: 200%;
      color: #525253;
      pointer-events: all;

      &:hover {
        color: #d7d7d8;
      }

      &:active {
        color: #f0f0f1;
      }
    }

    .left-btn-container {
      float: left;
    }

    .right-btn-container {
      float: right;
    }
  }

  #PopupLayer {
    width: 100%;
    height: 100%;
    position: absolute;

  }
}