#SVGPanel, #SubViewSVGPanel {

  .svg-panel {
    margin: 3% 0;
  }
  .svg-pointer-container {
    width: 90%;
    height: 30px;
    margin: 1% 5%;
  }

  .svg-pointer-input {
    display: inline-block;
    height: 98%;
    width: 70%;
    position: relative;

    .svg-point-color {
      width: 10px;
      height: 10px;
      display: inline-block;
      margin: 10px 10px 0;
    }

    .svg-column-input {
      width: 55%;
      height: 65%;
      padding: 0 5px;
    }

    .svg-column-select {
      width: 35px;
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .svg-pointer-btn-container {
    width: 30%;
    height: 100%;
    float: right;

    .svg-btn {
      width: 10px;
      background-color: #747475;
      border: 1px solid #747475;
      padding: 1px 6px;
      border-radius: 5px;
      margin: 7px 3px;
      display: inline-block;
      font-size: 10px;
      cursor: pointer;

      &:hover {
        color: #fafafa;
        border: 1px solid #8a8a8a;
        background-color: #8a8a8a;
      }
    }
  }
}