#CreateNewBook {
  width: 480px;
  outline: 1px solid #363636;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  position: absolute;

  .title-container {
    width: 100%;
    height: 42px;
    background-color: #3d3d3d;
    font-size: 16px;

    .title-text {
      width: 80%;
      margin: 10px 0 10px 15px;
      float: left;
    }

    .title-x-btn {
      display: inline-block;
      float: right;
      margin: 11px 12px 0 0;
      border: 0;
      background: none;
    }

    .icon_close {
      width: 20px;
      height: 20px;
      background-color: #7b7b7b;
      -webkit-mask-image: url("../../../images/icon-close.svg");
      mask-image: url("../../../images/icon-close.svg");
      -webkit-mask-size: 20px 20px;
      mask-size: 20px 20px;
      cursor: pointer;

       &:hover {
         background-color: #acacac;
       }

      &:active {
         background-color: #ffffff;
       }

    }
  }

  .contents-area {
    background-color: #525252;
  }

  .contents-container {
    width: calc(100% - 80px);
    padding: 20px 40px 15px 40px;

    .cover-preview-container {
      width: 100%;
      height: 84px;

      .cover-preview {
        background-repeat: no-repeat;
        background-size: contain;
        width: 150px;
        height: 84px;
        float: right;
      }
    }
  }

  .button-container {
    position: relative;
    width: 100%;
    padding-bottom: 40px;

    .btn {
      outline: 0;
      font-size: 1.0em;
      margin: 0 auto;
      display: block;
      padding: 5px 0px;
      border-radius: 5px;
      cursor: pointer;
      line-height: 1.8;
      width:400px;

      &.de-active {
        pointer-events: none;
        background-color: #575757;
      }
    }
  }
}