.log-component-area {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #212224;
  display: block;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.select-box {
  display: inline-block;
  cursor: pointer;
  width: 220px;
  padding: 2px 8px 5px 8px;
  border-radius: 2px;
  background-color: #4a4a4a;
  border: 1px solid #666666;
  color: #ebebeb;
  font-size: 0.95rem;
  text-align-last: left;
}

.log-content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:90px;


  .log-content-center {
    width: 50%;
    height: auto;
    margin: 0 auto;
    background-color: #323232;
    border-radius: 3px;
    border: 1px solid #3d3d3d;
    padding: 30px 50px 40px 50px;
  }

}