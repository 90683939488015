.user-list-table {
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    margin: 0 0 100px 0;

    th {
        background-color: #3e3e3e;
        height: 25px;

        &.idx {
            width: 5%;
        }
        &.id {
            width: 20%;
        }
        &.company {
            width: 25%;
        }
        &.expireDate {
            width: 40%;
        }
        &.active {
            width: 10%;
        }
    }

    tr.no-passed {
        td {
            background-color: #691011;
        }
    }

    td {
        background-color: #565656;
        height: 25px;

        input {
            text-align: center;
        }

        .expireDate-value {
            margin: 0 10px;

            .year-text {
                font-size: 12px;
                margin: 0 3px;
            }
            .month-text {
                font-size: 12px;
                margin: 0 3px;
            }
            .day-text {
                font-size: 12px;
                margin: 0 3px;
            }

        }

        .expireDate-input {
            width: 40%;

            &.year {
                width: 20%;
            }
            &.month {
                width: 10%;
            }
            &.day {
                width: 10%;
            }
        }

        .btn_gray {
            font-size: 13px;
            padding: 0 13px;
            background-color: #474747;

            &:hover {
                background-color: #1473e6;
            }
            &:active {
                background-color: #0565d9;
            }
        }
    }
}

.switch_label {
    width: 34px;
    position: relative;
    display: inline-block;
    padding-top: 5px;
}

.switch_content {
    display: block;
    cursor: pointer;
    position: relative;
    border-radius: 7px;
    height: 14px;

    background-color: #454545;
    -webkit-transition: all .1s .4s;
    -moz-transition: all .1s .4s;
    -ms-transition: all .1s .4s;
    -o-transition: all .1s .4s;
    transition: all .1s .4s;
    overflow: hidden;
}

.switch_content:after {
    content: "";
    display: block;
    position: absolute;

    width: 0;
    height: 100%;
    top: 0;
    left: 0;

    border-radius: 7px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.switch_input {
    display: none;
}

.switch_circle {
    display: block;
    top: 5px;
    left: 0px;
    position: absolute;
    width: 14px;
    height: 14px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-color: #7f7f7f;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
}

.switch_input:checked ~ .switch_circle {
    left: 21px;
    background-color: #ffffff;
}

.switch_input:checked ~ .switch_content {

    border-color: transparent;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -ms-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
}

.switch_input:checked ~ .switch_content:after {
    background-color: #46a0ef;
    width: 100%;
}