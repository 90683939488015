#PopupPanel {
  .popupTargetTable {
    width: 100%;

    .popupBtn {
      width: 30%;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 11px;
      text-align: center;
      background-color: #444444;
      border-radius: 5px;
      padding: 3px 6px 1px 6px;
      border: 1px solid #676767;
      margin: 5px;
      height: 30px;
      outline: none;

      &:hover {
        background-color: #383838;
      }

      &:active {
        background-color: #343434;
      }
    }

    .td-btn {
      width: 50%;
    }

    .td-name {
      width: 30%;
    }

    .td-value {
      width: 70%;
    }

    input[type="number"] {
      width: 70%;
      padding: 4px 3px 4px 5px;
      background-color: #454545;
      border: 1px solid #676767;
      text-align: left;
      color: #e2e2e2;
      font-size: 12px;
      cursor: pointer;
    }
  }

}
