.receipt-component-area {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #212224;
  display: block;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.receipt-tab {
  width: calc(80% + 58px);
  color: #FFFFFF;
  margin: 0 auto;

  .tab-item {
    background-color: #323232;
    border-radius: 4px 4px 0 0;
    border: 1px solid #3d3d3d ;
    border-bottom: 1px solid #424242;
    padding:15px 50px;
    cursor: pointer;
    font-size: 22px;
    color:#868686;

    &:hover {
      color:#ffffff;
      background-color: #5e5e5e;
    }
    &.selected {
      color:#ffffff;
      background-color: #424242;
    }
  }


}

.receipt-content {
  width: 100%;
  height: auto;
  display: block;
  justify-content: center;
  align-items: center;
  margin-top:90px;


  .receipt-content-center {
    width: 80%;
    height: auto;
    margin: 0 auto;
    background-color: #424242;
    border-radius: 0px 3px 3px 3px;
    border: 1px solid #3d3d3d;
    padding: 30px 28px 13px;
    margin-top:10px;
  }

  .list-title {
    font-size: 22px;
    color: #FFFFFF;
    padding-left: 3px;
    line-height: 3.0rem;
  }

  .list-right {
    float: right;
    width: 22%;
    margin-bottom: 10px;
  }

  .list-left {
    float: left;
    width: 78%;
  }

  .list-table {
    width: 100%;
    text-align: center;
    color: #4d4d4d;
    border-collapse: collapse;

    th {
      background-color: #d7d7d7;
      line-height: 2.5rem;
      border: 1px solid #bebebe;

      &.idx {
        width: 5%;
      }
      &.id {
        width: 17%;
      }
      &.subject {
        width: 30%;
      }
      &.type {
        width: 16%;
      }
      &.date {
        width: 22%;
      }
      &.totalPage {
        width: 10%;
      }
    }

    td {
      background-color: #FFFFFF;
      line-height: 2.5rem;
      border: 1px solid #bebebe;
    }

    .total-tr {
      line-height: 2.5rem;
      font-weight: 600;
    }

    .page-td {
      background-color: transparent;
      color: #1473e6;
      padding: 5px 0 0 0;
      border: 0px solid #bebebe;
    }

    .page-item {
      margin: 0 10px 0 0;
      font-size: 15px;
      text-decoration: underline;
      cursor: pointer;

      &.selected {
        color: #919191;
        cursor: default;
        text-decoration: none;
        font-weight: normal;

      }
    }
  }

}

.btn-csv {
  border: 0;
  background-color: #1473e6;
  color: #ffffff;
  border-radius: 4px;
  margin: 0;
  width: 100%;
  padding: 0px 17px;
  font-size: 1.15rem;
  line-height: 2.4;
  cursor: pointer;
  float: left;
  outline:none;

  &:hover {
    background-color: #0565d9;
    color: #ffffff;
  }

  &:active {
    background-color: #0565d9;
    color: #ffffff;
  }
}