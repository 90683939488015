#PagePanel {
  .panel-page-view {
    width: 180px;
    right: 5px;
    top: 10px;
  }

  .panel-page-view-inner .inner-bottom {
    width: 100%;
    /*height: 55px;
    border-top: 1px solid #444445;
    text-align: left;*/
    margin: 1px 0 0 0px;
    float: left;
  }

  .panel-page-view-inner .inner-bottom .box_marginspace{
	margin: 10px 0 0 6px;
	text-align:left;
     }

  .panel-page-view-inner .inner-bottom .btn_left{
	float:left;
	width:62%;
        text-align: left;
        margin-left: 6px;
      }

  .panel-page-view-inner .inner-bottom .btn_right{
	float:right;
	width:30%;
	text-align: right;
        margin-right: 6px;
      }

  .panel-page-view-inner .inner-bottom .page-btn {
    font-size: 0.75rem;
    color: #e3e3e3;
    padding: 4px 8px;
    margin: 0 2px;
    background-color: #4b4b4b;
    border-radius: 4px;
    border: 1px solid #828282;
    cursor: pointer;
    outline: none;

      &:hover, selected{
        color: #fafafa;
        border: 1px solid #8a8a8a;
        background-color: #8a8a8a;
      }
  }

  .panel-page-view-inner .inner-bottom .lockBtn {
    background-image: url("../../../../../images/icon-lock.png");
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    width: 9px;
    height: 18px;
  }

  .panel-page-view-inner .inner-bottom .unlockBtn {
    background-image: url("../../../../../images/icon-unlock.png");
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    width: 9px;
    height: 18px;
  }

  .panel-page-view-inner .inner-bottom .addBtn {
    background-color: #4A4A4B;
    margin: 0 3px 0px 2px;
  }

  .panel-page-view-inner .inner-bottom .updateBtn {
    background-color: #4A4A4B;
    margin-right: 10px;
  }

  .panel-page-view-inner .inner-bottom .removeBtn {
    background-color: #4A4A4B;

  }

  .panel-page-view-inner .inner-bottom .leftImageBtn {
    background-color: #747475;
    border: 1px solid #747475;
    padding: 4px 6px;
    margin-right: 6px;
  }

  .panel-page-view-inner .inner-bottom .rightImageBtn {
    background-color: #747475;
    border: 1px solid #747475;
    padding: 4px 6px;
  }

  .panel-page-view-inner .inner-grid {
    margin: 8px 7px 6px 7px;
    height: 268px;
    background-color: #ffffff;
    overflow: auto;
    outline: none;
    border: 1px solid #4b4b4b;
    border-radius:2px;
  }

  .panel-page-view-inner .grid-wrapper {
    /*grid-auto-rows: 60px;*/
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .grid-menu .pages {
    width: 140px;
    margin: 5px auto;
    padding: 12px 0px;
    height: 100px;
  }

  .grid-menu .pageLeft,
  .grid-menu .pageRight {
    float: left;
    width: 50%;
  }

  .pageLeft:hover .changePage,
  .pageRight:hover .changePage {
    position: relative;
    width: 20px;
    height: 20px;
    top: -100%;
    background: blue;
    opacity: .5;
    /*background: url("../image/icon-pic.svg") center no-repeat;*/
    background-size: cover;
    cursor: pointer;
    padding: 10px 0px;
  }

  .pageRight:hover .changePage {
    right: calc(-100% + 20px);
  }

  .pageLeft:hover .changePage:hover,
  .pageRight:hover .changePage:hover {
    opacity: 1;
  }

  .pages img {
    background-color: #fff;
    border: 1px solid #a09f9f;
    width: 100%;
    height: 90px;
    float: left;
  
     &:hover {
      border: 1px solid #f20000;
     }
  
  }

  .pages .pageLabel {
    position: relative;
    padding: 2px;
  }

  .pageLeft .pageLabel {
    text-align: left;
    color: #707070;
    font-size:0.9rem;
  }

  .pageRight .pageLabel {
    text-align: right;
    color: #707070;
    font-size:0.9rem;
  }

  .pageRight .pageLabel.selected {
    color: #ffffff;
  }

  .grid-wrapper.selected {
    background-color: rgba(0,0,0,.15);
  }

  .pageEditUser {
    height: 0;
    text-align: left;
    color: #000000;

    .user-icon {
      width: 50px;
      height: 50px;
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;

      &.icon1 {
        background-image: url("../../../../../images/user-icon1.png");
      }
      &.icon2 {
        background-image: url("../../../../../images/user-icon2.png");
      }
      &.icon3 {
        background-image: url("../../../../../images/user-icon3.png");
      }
      &.icon4 {
        background-image: url("../../../../../images/user-icon4.png");
      }
      &.icon5 {
        background-image: url("../../../../../images/user-icon5.png");
      }
    }
  }

  .no-select {
    background-color: #484848;
    cursor: default;
  }

  .lock-user {
    height: 10px;
    color: #111111;
    font-weight: bold;

    &.my-lock {
      color: #0088FF;
    }
  }
}

