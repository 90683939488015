#Login {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #212224;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 9;

  ::placeholder {
    color: rgba(0,0,0,0.35);
  }

  .input_login {
    width: 450px;

    height: auto;
    min-height: 400px;

    .logo {
      display: block;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom:17px;
    }

    .logo_svg {
      display: block;
      width: 400px;
      height: 50px;

      background-color:#ffffff;
      -webkit-mask-image: url("../../../images/logo.svg");
      mask-image: url("../../../images/logo.svg");

      -webkit-mask-size: 400px 50px;
      mask-size: 400px 50px;
    }

    .login-form-wrap {
      display: block;
      border-radius: 4px;
      border: 1px #464646 solid;
      background-color: #333333;
      padding: 40px 35px;

      .spacing-base {
        margin-bottom: 14px!important;
      }
    }

    form {
      margin: 0 auto;
      display: block;
      height: auto;
      width: 100%;
    }

    input[type="text"],
    input[type="password"] {
      display: block;
      width: calc( 100% - 20px );
      height: 28px;
      padding: 4px 7px 4px 10px;
      font-size:14px;
      color:#333333;
      line-height: normal;
      background-color: #ededed;

      border: 1px solid #525252;
      border-radius: 3px;
      outline: 0;
    }

    ::-webkit-input-placeholder {
      color: #b0b0b0; 
    }

    .login-remember-check {
      cursor: pointer;
      font-size: 0.85rem;

      .login-remember-check .checkbox_size{
        height: 13px;
        width: 13px;
        vertical-align: top;
        bottom: -3px;
      }
    }

    .login-remember-check span {
      position: relative;
      padding-left: 5px;
      text-indent: 0;
      color:#e8e6e6;
    }

    input[type="button"] {
      width: 100%;
      height: 42px;
      padding: 6px 0px;

      font-size:1.0rem;
      color:#ffffff;
      line-height: 20px;
      cursor: pointer;
      background-color: #3182fd;
      box-shadow: inset 0 0 0 1px #277bf9;
      border-radius: 3px;
      border: none;
      outline: 0;
    }

    input[type="button"]:hover {
      border-style: none !important;
      background-color: #1473e6;
      box-shadow: inset 0 0 0 1px #0565d9;
    }

    input[type="button"]:active {
      border-style: none !important;
      background-color: #0565d9;
      box-shadow: inset 0 0 0 1px #025bc8;
    }
  }

  .login-error {
    color: #c60000;
    font-size: 12px;
    margin-bottom: 10px;
    width: 100%;
  }
}