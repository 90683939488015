#modal {
  .message-box {
    width: 400px;
    height: 250px;
    outline: 1px solid #363636;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  .title-container {
    width: 100%;
    height: 20%;
    background-color: #3d3d3d;
    font-size: 16px;

    .title-text {
      width: 80%;
      margin: 12px 0 10px 14px;
      float: left;
    }

    .title-x-btn {
      display: inline-block;
      float: right;
      margin: 11px 12px 0 0;
      border: 0;
      background: none;
    }

    .icon_close {
      width: 20px;
      height: 20px;
      background-color: #7b7b7b;
      -webkit-mask-image: url("../../images/icon-close.svg");
      mask-image: url("../../images/icon-close.svg");
      -webkit-mask-size: 20px 20px;
      mask-size: 20px 20px;
      cursor: pointer;
    
      &:hover {
        background-color: #acacac;
      }

      &:active {
        background-color: #ffffff;
      }
    
    }
  }

  .content-container {
    width: 100%;
    height: 80%;
    background-color: #535353;

    .content-message {
      padding: 6% 4%;
      font-size: 0.95rem;
      color: #eaeaea;
    }

    .input-page {
      width: 80px;
      height: 30px;
      border-radius: 5px;
      margin: 5px 0;
      padding: 0 0 0 10px;
      text-align: center;

      &::placeholder {
        opacity: 0.3;
      }

    }
  }

  .button-container {
    position: relative;
    height: 25%;
    bottom: calc(0px + 25%);
    margin-right:9px;

    .btn {
      outline: 0;
      font-size: 13px;
      float: right;
      width:100px;
      margin: 5px 4px;
      padding: 5px 9px;
      border-radius: 5px;
      border: 1px solid #747475;
      background-color: #4A4A4B;
      color: #FFFFFF;
      cursor: pointer;

      &:hover {
        background-color: #c7c7c7;
	background-color: #c7c7c7;
	color: #535353;
      }

      &:active {
        background-color: #e2e2e2;
	background-color: #e2e2e2;
	color: #535353;
      }
    }
  }
}