#Import {
    .ImportTable{
	margin:0 auto;
	padding: 50px 70px;
	background-color: #323232;
	border-radius: 3px;
        border: 1px solid #3d3d3d;
    }
    
    .csv-btn-group {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0px;
    }

    .csv-group-name {
        width: 300px;
	font-size: 1.05rem;
        color: #dddddd;
	font-weight: 500;
    }

    .csv-import-btn {
        width:150px;
	margin: 10px;
	padding: 6px 0px;
	cursor: pointer;
	background-color: #606060;
	border: 1px solid #555555;
	border-radius: 3px;
	line-height: 1.6;
	text-align: center;
	font-size: 0.95rem;
	color: #c3c3c3;

	&:hover {
	  border: 1px solid #8a8a8a;
	  background-color: #8a8a8a;
	  color: #ffffff;
	}

	&:active {
	  border: 1px solid #378bfe;
	  background-color: #8a8a8a;
	  color: #ffffff;
	}
    }

    .csv-export-btn {
        width:150px;
	margin: 10px;
	padding: 6px 0px;
	cursor: pointer;
	background-color: #606060;
	border: 1px solid #555555;
	border-radius: 3px;
	line-height: 1.6;
	text-align: center;
	font-size: 0.95rem;
	color: #c3c3c3;

	&:hover {
	  border: 1px solid #8a8a8a;
	  background-color: #8a8a8a;
	  color: #ffffff;
	}

	&:active {
	  border: 1px solid #378bfe;
	  background-color: #8a8a8a;
	  color: #ffffff;
	}
    }

    .csv-template-btn {
        width:150px;
	margin: 10px;
	padding: 6px 0px;
	cursor: pointer;
	background-color: #3182fd;
	border: 1px solid #277bf9;
	border-radius: 3px;
	line-height: 1.6;
	text-align: center;
	font-size: 0.95rem;
	color: #ffffff;

	&:hover {
	  background-color: #1473e6;
	  border: 1px solid #0565d9;
	  color: #ffffff;
	}

	&:active {
	  background-color: #0565d9;
	  border: 1px solid #025bc8;
	  color: #ffffff;
	}
    }
}
