#ListPanel {
  .panel-object-list {
    right: 5px;
  }

  .panel-object-list-inner .inner-top{
    width: 100%;
    text-align:left;
    padding:10px 10px 0 8px;
    font-size:0.75rem;
  }

  .panel-object-list-inner .inner-bottom {
    width: 100%;
    text-align:center;
    padding:3px 0px 5px 0px;
    font-size:0.75rem;
  
     .btnlink_box{
	width:62px;
	
	font-size: 0.75rem;
	color: #e3e3e3;
	
	padding: 4px 0px;
	margin:0 2px;

	background-color: #4b4b4b;
	border-radius: 4px;
	border: 1px solid #828282;
	
	cursor: pointer;
     
	&:hover, selected{
	  color: #fafafa;
	  border: 1px solid #8a8a8a;
	  background-color: #8a8a8a;
        }
     }

     .box_rightspace{
	margin-right:5px;
     }

    .small_width_btn {
      width: 31px;
    }
  }

  .panel-object-list-inner .inner-list {
    margin: 8px 7px 6px 7px;
    /*background-color: #fff;*/
  }

  .object-list-box {
    width: 100%;
    text-align: center;
    outline: none;
    border: 1px solid #828282;
    border-radius:2px;
  
      option {
	color: #333333;
	padding: 5px 10px 2px 10px;
	font-size: 0.8rem;
	cursor: pointer;
	text-align:left;
	min-height: 1.35em;
      }
  }

  .inner-page-num {
    font-size: 18px;
  }

  .inner-mask {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    top: 35px;
    left: 0;
    background-color: rgba(0, 0, 0, .0);
    padding: 0;
  }
}
