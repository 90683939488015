#RepoPanel {
  .finder-area {
    width: 50%;
    height: 100%;
    display: inline-block;
  }

  .finder-search-input {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .input-finder {
    display: inline-block;
    width: 77%;
    height: 27px;
    padding: 1px 10px;
    background-color: #414142;
    color: #ffffff;
    text-align: left;
    float: left;
    margin-left: 18px;
    
    border-width: 1px 0 1px 1px;
    border-color: #676767;
    border-style: solid;
    border-radius: 2px 0 0 2px;
  }

  .btn_search{
    display: inline-block;
    float:left;
    height: 27px;
    padding: 1px 5px;
    background-color: #414142;
    border-width: 1px 1px 1px 0;
    border-color: #676767;
    border-style: solid;
    border-radius: 0 2px 2px 0;
  }

  .finder-search-icon {
    width: 23px;
    height: 26px;
    background-color: #afafaf;
    -webkit-mask-image: url("../../images/icon-search.svg");
    mask-image: url("../../images/icon-home.svg");

    -webkit-mask-size: 23px 26px;
    mask-size: 23px 26px;

    cursor:pointer;

    &:hover {
      filter: brightness(0) invert(1);
    }
  }

  .finder-search-form {
    display: inline;
    width: 100%;
  }

}