.react-draggable.selected {
  z-index: 9;
}

.editorDragText {
  white-space: pre-wrap;
}

.handle:after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  border: 1px solid black;
  margin: 6px;
}

.object-item {
  position: absolute;
  width: 100%;
  height: 100%;

  svg {
    pointer-events: none;
    position: absolute; // Rect 크기가 작아질수록 배경이미지가 어긋나는 문제가 있어서 추가 (ex: 이과 checkBox, block 등)
  }

  polygon,
  path {
    stroke: #000;
    fill: none;
    stroke-dasharray: 4, 4;
    stroke-width: 1pt;
    opacity: .8;
    fill-opacity: 0;
  }

  .current-page-rect {
    stroke: #0000FF;
    stroke-width: 1pt;
  }

  .connected {
    stroke: #FF0FBF;
    stroke-width: 5pt;
  }

  .edit-text {
    position: absolute;
    width: 100%;
    height: 100%;
    outline: none;
    font-family: ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,Osaka,メイリオ,Meiryo,ＭＳ Ｐゴシック,MS PGothic,sans-serif;

    div {
      outline: 1px solid rgba(96, 96, 96, 0.3);
    }

    .line-hide {
      outline: none;
    }
  }

  .object-id {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 20px;
    word-break: break-all;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 2px gray;
    overflow: hidden;

    &.show {
      background-color: rgba(0, 0, 255, .2);
    }

    &.hide {
      display: none;
    }

    span {
      position: relative;
      width: 100%;
      top: 50%;
      left: 0;
    }
  }

  .vertical-write {
    writing-mode: vertical-rl;
  }

  &:focus {
    outline: unset;
  }

  &.selected {
    polygon,
    path {
      animation: dash 300ms infinite linear;
      stroke: #f00;
      opacity: 1 !important;
      stroke-width: 2pt;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 6px;
      }
    }
  }

  &.block {
    background-color: #fff;
    outline: 4px solid black;
  }

  &.rika {
    &.blocked {
      outline: .2em solid black;
      background-color: #FFFFFF;
    }
  }

  &.redLine {
    border-bottom: 5px solid #FF0000;
  }

  &.redLine-small {
    border-bottom: 2px solid #FF0000;
  }

  &.answer {
    &.answerButton {
      background-image: url("../../../../../images/arithmetic/btn-resolve-ka.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &.grade-1 {
        background-image: url("../../../../../images/arithmetic/btn-resolve-kn.png");
      }
    }
    &.expressionButton {
      background-image: url("../../../../../images/arithmetic/btn-bubble-expression.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.graphButton {
      background-image: url("../../../../../images/arithmetic/btn-bubble-graph.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.equalButton {
      background-image: url("../../../../../images/arithmetic/btn-bubble-equal.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.queEqualButton {
      background-image: url("../../../../../images/arithmetic/btn-bubble-queEqual.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.resolveButton {
      background-image: url("../../../../../images/arithmetic/btn-bubble-resolve.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.maskButton {
      background-color: rgba(0, 0, 255, 0.2);
    }
    &.maskContents {
      background-color: rgba(0, 150, 255, 0.2);
    }
  }

  &.rikaAnswer {
    &.answerButton {
      background-image: url("../../../../../images/t_icon/rika-answer.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.exampleButton {
      background-image: url("../../../../../images/t_icon/rika-example.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.popup {
    background-color: #efb381;
    opacity: 0.3;
  }

  .subView-rect-in-text {
    position: absolute;
    height: 100%;
    width: 100%;
    color: #000000;
    text-align: center;
    word-break: break-all;
    overflow: hidden;

    div {
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .data-num {
      color: #FFAA00;
      font-size: 25px;
      font-weight: bold;
      text-shadow: 2px 2px 2px grey;
    }

    .maskButton {
      color: white;
      font-weight: bold;
      text-shadow: 2px 2px 2px grey;
      text-align: left;
      opacity: 0.7;
    }
    .expressionButton {
      text-align: left;
    }
    .equalButton {
      text-align: left;
    }
    .resolveButton {
      text-align: left;
    }
    .maskContents {
      color: white;
      font-weight: bold;
      text-shadow: 2px 2px 2px grey;
      text-align: left;
      opacity: 0.7;
    }
    .expressionContents {
      text-align: left;
    }
    .equalContents {
      text-align: left;
    }
    .resolveContents {
      text-align: left;
    }

    .group-num {
      color: rgba(255, 255, 100, 1);
      background-color: rgba(170, 240, 209, 0.3);
      font-size: 25px;
      font-weight: bold;
      text-shadow: 2px 2px 2px rgba(152, 152, 152, 1);
    }
  }

  &.check1 {
    background-image: url("../../../../../images/natural_sciences/check_mark.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.check2 {
    background-image: url("../../../../../images/natural_sciences/check_mark.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.checkSheet {
    background: blue;
  }

  &.meaning-blue-right {
    border-right: .2em solid #0000FF;
  }
  &.meaning-blue-left {
    border-left: .2em solid #0000FF;
  }
  &.meaning-blue-bottom {
    border-bottom: .4em solid #0000FF;
  }
  &.meaning-blue-top {
    border-top: .4em solid #0000FF;
  }
  &.meaning-red-right {
    border-right: .2em solid #FF0000;
  }
  &.meaning-red-left {
    border-left: .2em solid #FF0000;
  }
  &.meaning-red-bottom {
    border-bottom: .4em solid #FF0000;
  }
  &.meaning-red-top {
    border-top: .4em solid #FF0000;
  }

  &.form::after {
    content: attr(data-descr);
    position: absolute;
    width: 1.4em;
    height: 1.4em;
    color: #fff;
    background-color: #0050aa;
    text-align: center;
    vertical-align:middle;
    border-radius: 50%;
    border: 0 solid #0050aa;
    font-size: 0.9em;
    font-family: initial;
    line-height: 1.4em;
  }

  &.icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.answer {
      background-image: url("../../../../../images/t_icon/answer.svg");
    }
    &.content {
      background-image: url("../../../../../images/t_icon/content.svg");
    }
    &.content_common {
      background-image: url("../../../../../images/t_icon/content_common.svg");
    }
    &.external_link {
      background-image: url("../../../../../images/t_icon/external_link.svg");
    }
    &.external_link_common {
      background-image: url("../../../../../images/t_icon/external_link_common.svg");
    }
    &.flashcards {
      background-image: url("../../../../../images/t_icon/flashcards.svg");
    }
    &.eigo.flashcards {
      background-image: url("../../../../../images/t_icon/flashcards_eigo.svg");
    }
    &.flashcards_common {
      background-image: url("../../../../../images/t_icon/flashcards_common_eigo.svg");
    }
    &.guide {
      background-image: url("../../../../../images/t_icon/guide.svg");
    }
    &.movie {
      background-image: url("../../../../../images/t_icon/movie.svg");
    }
    &.movie_common {
      background-image: url("../../../../../images/t_icon/movie_common.svg");
    }
    &.page_link {
      background-image: url("../../../../../images/t_icon/page_link.svg");
    }
    &.reading_sound {
      background-image: url("../../../../../images/t_icon/reading_sound.svg");
    }
    &.reading_sound_common {
      background-image: url("../../../../../images/t_icon/reading_sound_common.svg");
    }
    &.related_document {
      background-image: url("../../../../../images/t_icon/related_document.svg");
    }
    &.related_document_common {
      background-image: url("../../../../../images/t_icon/related_document_common.svg");
    }
    &.simulation {
      background-image: url("../../../../../images/t_icon/simulation.svg");
    }
    &.simulation_common {
      background-image: url("../../../../../images/t_icon/simulation_common.svg");
    }
    &.map {
      background-image: url("../../../../../images/t_icon/map.svg");
    }
    &.slideshow {
      background-image: url("../../../../../images/t_icon/slideshow.svg");
    }
    &.voice_common {
      background-image: url("../../../../../images/t_icon/voice_common.svg");
    }
    &.work_sheet {
      background-image: url("../../../../../images/t_icon/work_sheet.svg");
    }
    &.work_sheet_common {
      background-image: url("../../../../../images/t_icon/work_sheet_common.svg");
    }
    &.looking_back {
      background-image: url("../../../../../images/t_icon/looking_back.svg");
    }
    &.move_left {
      background-image: url("../../../../../images/t_icon/move_left.png");
    }
    &.move_right {
      background-image: url("../../../../../images/t_icon/move_right.png");
    }
    &.stumble {
      background-image: url("../../../../../images/t_icon/stumble.svg");
    }

    &.cont {
      background-image: url("../../../../../images/t_icon/cont.svg");
    }
    &.cont_qr {
      background-image: url("../../../../../images/t_icon/cont_qr.svg");
    }
    &.furika {
      background-image: url("../../../../../images/t_icon/furika.svg");
    }
    &.kaito {
      background-image: url("../../../../../images/t_icon/kaito.svg");
    }
    &.link {
      background-image: url("../../../../../images/t_icon/link.svg");
    }
    &.link_qr {
      background-image: url("../../../../../images/t_icon/link_qr.svg");
    }
    &.map_red {
      background-image: url("../../../../../images/t_icon/map_red.svg");
    }
    &.movie_qr {
      background-image: url("../../../../../images/t_icon/movie_qr.svg");
    }
    &.pd {
      background-image: url("../../../../../images/t_icon/PD.svg");
    }
    &.pd_student {
      background-image: url("../../../../../images/t_icon/PD_student.svg");
    }
    &.rensyu {
      background-image: url("../../../../../images/t_icon/rensyu.svg");
    }
    &.rensyu_qr {
      background-image: url("../../../../../images/t_icon/rensyu_qr.svg");
    }
    &.sheet {
      background-image: url("../../../../../images/t_icon/sheet.svg");
    }
    &.sheet_qr {
      background-image: url("../../../../../images/t_icon/sheet_qr.svg");
    }
    &.slide {
      background-image: url("../../../../../images/t_icon/slide.svg");
    }
    &.slide_qr {
      background-image: url("../../../../../images/t_icon/slide_qr.svg");
    }
    &.sim {
      background-image: url("../../../../../images/t_icon/sim.svg");
    }
    &.siryou {
      background-image: url("../../../../../images/t_icon/siryou.svg");
    }
    &.siryou_qr {
      background-image: url("../../../../../images/t_icon/siryou_qr.svg");
    }
    &.sound {
      background-image: url("../../../../../images/t_icon/sound.svg");
    }
    &.sound_qr {
      background-image: url("../../../../../images/t_icon/sound_qr.svg");
    }
    &.text {
      background-image: url("../../../../../images/t_icon/text.svg");
    }
    &.toujo {
      background-image: url("../../../../../images/t_icon/toujo.svg");
    }
    &.map_r6 {
      background-image: url("../../../../../images/t_icon/map_r6.svg");
    }
    &.movie_r6 {
      background-image: url("../../../../../images/t_icon/movie_r6.svg");
    }
    &.arasuji {
      background-image: url("../../../../../images/t_icon/arasuji.png");
    }
    &.mondai_jitsu {
      background-image: url("../../../../../images/t_icon/mondai_jitsu.png");
    }
    &.mondai_kiso {
      background-image: url("../../../../../images/t_icon/mondai_kiso.png");
    }
    &.movie_anime {
      background-image: url("../../../../../images/t_icon/movie_anime.png");
    }
    &.shidousha_digital {
      background-image: url("../../../../../images/t_icon/shidousha_digital.png");
    }
    &.sim_student {
      background-image: url("../../../../../images/t_icon/sim_student.png");
    }
  }
}
