.log-list-table {
  width: 100%;
  text-align: center;
  color: #FFFFFF;
  margin: 20px 0 100px 0;

  th {
    background-color: #3e3e3e;
    height: 25px;

    &.idx {
      width: 5%;
    }
    &.userId {
      width: 15%;
    }
    &.page {
      width: 10%;
    }
    &.type {
      width: 15%;
    }
    &.action {
      width: 15%;
    }
    &.objectId {
      width: 15%;
    }
    &.regDate {
      width: 25%;
    }
  }


  td {
    background-color: #565656;
    height: 25px;

    input {
      text-align: center;
    }

  }
}

.react-datepicker__tab-loop {
  display: inline-block;
}

.page-td {
  background-color: transparent;
  color: #1473e6;
  padding: 5px 0 0 0;
  border: 0px solid #bebebe;
}

.total-tr {
  line-height: 2.5rem;
  font-weight: 600;
}

.page-item {
  margin: 0 10px 0 0;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;

  &.selected {
    color: #919191;
    cursor: default;
    text-decoration: none;
    font-weight: normal;

  }
}

.between_date {
  color: #FFFFFF;
  margin: 0 5px;
}

.margin-right-10 {
  margin-right: 10px;
}