.book-type-password {
    width: 100%;
    height: 40%;
    display: flex;
    margin-bottom:20px;

    .book-type-title {
        width: 80%;
        height: auto;
        margin: 18px 0px 10px 0px;
        display: inline-block;
	font-size: 1.0rem;
	color: #dddddd;
	font-weight: 500;
	line-height:2.0;
    }

    .book-password {
        width: 100%;
        height: 80%;
        margin: 10px 0px;
        display: inline-block;

        .password-input-area{
            width: 90%;
            height: 60%;

            .book-password-input {
                width: 100%;
                height: 20%;
                background-color: #ededed;
		border: 1px solid #464646;
                margin: 10px 0px;
                display: block;
                padding: 8px 10px;
		border-radius: 3px;
		outline: 0;
            }

	    ::-webkit-input-placeholder {
	        color: #b0b0b0; 
	    }
         }

        .password-save{
            margin: 10px 0;
            width: 100%;
            height: 40%;
        }
    }
}

.autosave-time-select {
    margin: 0px 0px 0px 8px;
    width: 46%;
    height: 100%;
    float: left;
    padding: 5px 5px 7px 4px;
    border-radius:2px;

    background-color: #4a4a4a;
    border: 1px solid #666666;
    color: #ebebeb;
    font-size: 0.85rem;
    cursor: pointer;
}

