#TopPanel {
  display: flex;
  position: fixed;
  top: 0;
  /*
  left: calc(50% - 640px);
  */
  width: auto;
  height: 44px;
  font-size: 0.75rem;
  background-color: #505050;
  justify-content: center;
  align-items: center;

  padding: 3px 12px;

  border-style: solid;
  border-color: #323232;
  border-width: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);

  z-index: 100;

  div {
    margin: 0 5px;
  }

  .toggle-icon {
    cursor: pointer;
    height: auto;
    background-color: #4b4b4b;
    border: 1px solid #676767;
    margin: 0 3px;
    padding: 3px 10px 5px;
    border-radius: 3px;

    line-height: 1.6;
    text-align: center;
    color: #a9a9a9;
    float: left;
    position: relative;

    &.toggled {
      background-color: #383838;
      border: 1px solid #ffffff;
      color: #ffffff;
    }

    &:hover {
      border: 1px solid #177def;
      color: #46a0f5;
      background-color: #444444;
    }

    &:active {
      border: 1px solid #177def;
      color: #46a0f5;
      background-color: #383838;
    }

    &.de-active {
      pointer-events: none;
      background-color: #575757;
    }
  }
  
  .zoom-wrapper {
    display: flex;
    width: 200px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0 3px 0 4px;

    .zoom-text {
      color: #979797;
      font-size: 15px;
      margin: 0 0 2px 5px ;
      width: 70px;
    }

    .zoom-plus {
      color: #979797;
      font-size: 30px;
      margin: 0 0 9px 10px ;
    }

    .zoom-minus {
      color: #979797;
      font-size: 30px;
      margin: 0 10px 9px 0;
    }
  }

  .zoom-wrapper .zoom-slider {
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 40px;
    width: 200px;
    cursor: pointer;
    border-radius: 0;
    background-color: transparent;

    &:focus, :active {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      height: 2px;
      background: #979797;
      border-radius: 15px;
      width: 100%;
    }

    &::-webkit-slider-thumb {
      height: 18px;
      width: 18px;
      border-radius: 10px;
      background-color: #4A4A4B;
      -webkit-appearance: none;
      appearance: none;
      border: 2px solid #979797;
      margin-top: -8px;
      cursor: pointer;

      &:hover {
        background-color: #3f3f3f;
      }

      &:active {
        background-color: #383838;
        border: 2px solid #dfdfdf;
      }
    }
  }

  .mode-wrapper {
    margin: 0 7px 0 4px;
  }

  .mode-wrapper .mode-select {
    cursor: pointer;
    height: 28px;
    background-color: #4b4b4b;
    border: 1px solid #676767;

    padding: 3px 4px;
    border-radius: 3px;
    line-height: 2.5;
    color: #f6f6f6;

    margin-left: 5px;

    /*outline: none;*/
  }

  .mode-wrapper .mode-select option {
    /*border: 1px solid #676767;*/
    padding: 3px 10px;
    cursor: pointer;
  }

  .etc-wrapper {
  }
}
