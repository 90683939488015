#CheckSheetPanel {
  .inner-handle {
    position: relative;
    height: 100%;
    width: 32px;
    display: inline-block;
    margin: 0;
    padding: 0;
    border-bottom: 0;
    border-right: 1px solid #393939;
    float: left;
  }

  .inner-contents {
    position: relative;
    height: 100%;
    width: calc(100% - 33px);
    display: inline-block;

    .inner-list {
      width: calc(100% - 22px);
      height: calc(100% - 22px);
      margin: 5px;
      padding: 5px;
      background-color: #ffffff;
      overflow: auto;
      outline: none;
      border: 1px solid #4b4b4b;
      border-radius: 2px;
      position: relative;

      .check-sheet-area {
        min-width: 110px;
        width:max-content;
        height: calc(100% - 14px);

        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 5px;

        .check-sheet-item {
          border: 2px dotted #8c8c8c;
          border-radius: 5px;
          padding: 5px 5px;
          color: #707070;
          margin: 0px 0px 0px 10px
        }

        .check-item {
          width: 90px;
          height: 90px;
          margin: 0px 0px 0px 0px;
        }

        .icon_Check1 {
          background-image: url("../../../../../images/natural_sciences/check_mark.png") ;
          background-repeat: no-repeat;
          background-size: contain;
        }


        .icon_Check2 {
          background-image: url("../../../../../images/natural_sciences/check_mark.png") ;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}