#Setting {

  width: 100%;
  height: 100%;
  position: absolute;
  background: #212224;
  display: block;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;

  .setting-content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
  }

  .setting-content-center {
    width: 50%;
    height: auto;
    margin: 0 auto;
    background-color: #323232;
    border-radius: 3px;
    border: 1px solid #3d3d3d;
    padding: 45px 70px;
  }

  .component-column-area {
    width: 100%;
    height: 70%;
    margin: 0 auto;
    display: block;

    .select-box-with-label {
      margin: 30px 0;
    }
  }

  .component-save-area {
    width: 100%;
    height: 24%;
    display: flex;
    margin: auto;
  }

  .setting-save-btn {
    margin: 15px auto auto;
    display: block;
    width: 435px;
    height: auto;
    padding: 4px 0;

    line-height: 2.0;
    font-weight: normal;
    font-size: 1.05em;
    text-align: center;
  }

  .chapter-setting-container,
  .bookIndex-setting-container {
    margin: 45px 0;
    color: #ffffff;

    .chapter-label {
      margin: 10px 0;
      display: inline-block;
    }

    .control-box {
      margin: 10px 0;
      float: right;

      .add-chapter-btn {
        width: 75px;
        margin-left: 15px;
      }
    }

    .chapter-table {
      width: 100%;
      text-align: center;

      th {
        background-color: #3e3e3e;
        height: 25px;

        &.name {
          width: 50%;
        }

        &.idx, &.start, &.end {
          width: 10%;
        }

        &.btn {
          width: 20%;
        }

        &.page, &.pageIndex {
          width: 40%;
        }
      }

      tr.no-passed {
        td {
          background-color: #691011;
        }
      }

      td {
        background-color: #565656;
        height: 25px;

        input {
          text-align: center;

          &[type="text"],
          &[type="number"]{
            height: 100%;
            width: 90%;
            background-color: inherit;
            color: #ffffff;

            &:focus {
              background-color: #ffffff;
              color: #343434;
            }
          }
        }

        .btn_gray {
          font-size: 12px;
          padding: 0 12px;
          background-color: #676767;

          &:hover {
            background-color: #1473e6;
          }
          &:active {
            background-color: #0565d9;
          }
        }
      }
    }
  }

}

