#Home {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 auto;
  background: #202020;

  .btnlist-container {
    position: fixed;
    top: 0;
    left:65px;
    width: calc(100% - 82px);
    height: 90px;
    margin: 0px;
    background: #202020;
    z-index:1;

    .homebtn-left {
      float: left;
      width: 20%;
      padding:20px 0px 0 50px;
    }

    .homebtn-right {
      float: right;
      width: auto;
      padding:20px 55px 0 0;
    }

    .home-new-btn {
      width: 200px;
      height: 43px;
      margin: 5px;
    }

    .home-edit-btn {
      width: 148px;
      height: 43px;
      margin: 5px;
    }

    .home-edit-text {
      font-size: 0.95rem;
      line-height: 1.4;
      color: #a2a2a2;
      text-align: center;
      font-weight: bold;

      background-color: #383838;
    }
  }

  .box-container {
    margin: 30px 50px 0 50px;
    position: relative;
    top: 90px;

    .box-container {
      width: 93%;
      margin: 30px auto 0 auto;
    }

    .border-bottom {
      border-bottom: 1px solid #333333;
    }

    .home-title {
      font-size: 1.4rem;
      color: #ffffff;
      margin: 0 0 20px 0;
      padding: 0 0 3px 7px;
    }

    .book-component {
      margin-bottom: 60px;
    }

    .editing-book {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0px;

      color:#727272;
      line-height: 2.0;
    }

    .list-books {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0px;
      color:#666666;
      padding-left:5px;
    }

    .book-list{
      margin:0 5px 5px 0;
    }

    .book-image {
      width: 100%;
      height: 150px;
      margin: 0;
      background-color: #282828;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .change-cover {
        cursor: pointer;
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 50px;
        height: 20px;
        background-color: green;
        border-radius: 5px;
        text-align: center;
      }

      &:hover {
        .change-cover {
          display: block;
        }
      }
    }

    .book-info {
      width: 88%;
      text-align: left;
      padding: 12px 15px 20px 15px;
      color: #a8a8a8;
      font-size: 0.9rem;
      line-height: 1.45;
    }

    .info-area{
      padding-bottom:18px;
    }

    .book-title {
      font-size: 0.95rem;
      color: #eaeaea;
      line-height: 1.7;
    }

    .bottom-btn-container {
      position: fixed;
      bottom: 0;
      padding: 0 10px;
      width: calc(100% - 60px);
      background-color: #2b2b2b;
      border-top: 1px solid #303030;
    }

    .btn-area-space {
      margin-right: 15px;
    }

    .btn-upload {
      float: left;
      width: 100%;
      padding: 6px 0;
      margin: 0px 0 8px 0;

      font-size: 0.92rem;
      cursor: pointer;
      background-color: #7c7c7c;
      border: 1px solid #818181;
      border-radius: 4px;
      color: #ffffff;
      text-align: center;
      outline: none;

	  &:hover {
	    background-color: #1473e6;
	    border: 1px solid #0565d9;
	    color: #ffffff;
	  }

	  &:active {
	    background-color: #0565d9;
	    border: 1px solid #025bc8;
	    color: #ffffff;
	  }

      &:disabled {
        background-color: #424242;
        border: 1px solid #494949;
        cursor: not-allowed;
      }

      &.disabled {
        background-color: #424242;
        border: 1px solid #494949;
        cursor: not-allowed;
      }
    }

    .editor-msg {
      color: #AA0000;
      font-size: 12px;
    }

    label.btn-upload {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .editing-user-title {
      color: #3EB489;
      font-weight: bold;
    }

    .editing-user-list {
      color: #3EB489;
    }
  }
}