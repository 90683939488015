.select-box-with-label {
  display: block;
  margin: 15px 0;
  width: 100%;
  height: 25px;
  font-size:0.95rem;
  line-height:2.0;

  .label {
    display: inline-block;
    font-size: 1.0rem;
    color:#ffffff;
    line-height:1.95;
  }

  .select-box-container {
    display: inline-block;
    float: right;

    .select-box {
      display: inline-block;
      cursor: pointer;

      width: 220px;
      padding: 2px 8px 5px 8px;
      border-radius: 2px;
      background-color: #4a4a4a;
      border: 1px solid #666666;
      color: #ebebeb;
      font-size: 0.95rem;
      text-align-last: left;
    }
  }
}

