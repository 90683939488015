.add-group-btn {
  width: 120px;
  float: right;
}

.group-list-table {
  width: 100%;
  text-align: center;
  color: #FFFFFF;
  margin: 0 0 100px 0;

  th {
    background-color: #3e3e3e;
    height: 25px;

    &.idx {
      width: 5%;
    }
    &.groupName {
      width: 30%;
    }
    &.user {
      width: 20%;
    }
    &.controller {
      width: 15%;
    }
    &.addGroup {
      width: 30%;
    }
  }

  td {
    background-color: #565656;
    height: 25px;

    input {
      text-align: center;
    }

    .btn_gray {
      font-size: 13px;
      padding: 0 13px;
      background-color: #474747;

      &:hover {
        background-color: #1473e6;
      }
      &:active {
        background-color: #0565d9;
      }
    }

    .td-in-input-text {
      width: 80%;
    }
  }

  .td-background {
    background-color: #323232;
  }

  .td-click-area {
    cursor: pointer;
  }
}