#root {
  width: inherit;
  height: inherit;
  /*
  background: repeating-linear-gradient(140deg, #e8e8e8, #e8e8e8 2px, #ececec 0, #ececec 4px);
  */
  background: #212224;
  -webkit-user-drag: none;
  user-select: none;

}

input[type=text],
input[type=number],
input[type=email],
input[type=password],
input[type=tel],
input[type=url],
input[type=search] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  outline: 0;
  padding: 0;
  border: 0;
}

input[type=button] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

select {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

.hide {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
}

.gray-screen {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.book {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  cursor: pointer;

  width: 260px;
  margin: 0 20px 20px 0;
  padding: 0;
  background-color:#343434;
  border:1px solid #3d3d3d;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.2);

  font-size: 0.9rem;
  color: #b5b5b5;
  text-align: left;
  line-height: 1.2;

  &:hover, &:active, &.selected {
    border: 1px solid #025bc8;
    color: #ffffff;
  }

  &.de-active {
    pointer-events: none;
    background-color: #656565;

    &:before {
      color: #ffffff;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      content: "";
      text-align: center;
      font-size: 1.45rem;
      line-height: 230px;
      background-color: rgba(0, 4, 10, .8);
    }

    &.editing:before {
      content: "編集中";
    }

    &.compressing:before {
      content: "圧縮中";
      pointer-events: auto;
    }

    &.converting:before {
      content: "変換中";
      pointer-events: auto;
    }

    &.waiting:before {
      content: "待機中";
      pointer-events: auto;
    }
  }
}

.btn_blue {
  cursor: pointer;
  background-color: #3182fd;
  border: 1px solid #277bf9;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  outline: none;

  &:hover {
    background-color: #1473e6;
    border: 1px solid #0565d9;
    color: #ffffff;
  }

  &:active {
    background-color: #0565d9;
    border: 1px solid #025bc8;
    color: #ffffff;
  }
}

.btn_gray {
  font-size: 0.95rem;
  cursor: pointer;
  background-color: #575757;
  border: 1px solid #5f5f5f;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  outline: none;

  &:hover {
    background-color: #1473e6;
    border: 1px solid #0565d9;
    color: #ffffff;
  }

  &:active {
    background-color: #0565d9;
    border: 1px solid #025bc8;
    color: #ffffff;
  }
}

.btn_gray_border_round {
  font-size: 0.92rem;
  cursor: pointer;
  background-color: #575757;
  border: 1px solid #5f5f5f;
  border-radius: 20px;
  color: #ffffff;
  text-align: center;
  outline: none;

  &:hover {
    background-color: #1473e6;
    border: 1px solid #0565d9;
    color: #ffffff;
  }

  &:active {
    background-color: #0565d9;
    border: 1px solid #025bc8;
    color: #ffffff;
  }
}

.btn_openfile {
  font-size: 0.95rem;
  line-height: 1.4;
  color: #e3e3e3;
  text-align: center;
  font-weight: bold;

  cursor: pointer;
  outline: none;
  background-color: #262627;
  border: 2px solid #e3e3e3;
  border-radius: 30px;

  &:hover {
    background-color: #e3e3e3;
    border: 2px solid #e3e3e3;
    color: #202020;
  }

  &:active {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #202020;
  }
}

.btn_white_line {
  font-size: 0.95rem;
  line-height: 1.4;
  color: #a2a2a2;
  text-align: center;
  font-weight: bold;

  cursor: pointer;
  outline: none;
  background-color: #262627;
  border: 2px solid #8d8d8d;
  border-radius: 30px;

  &:hover {
    background-color: #b4b4b4;
    border: 2px solid #b4b4b4;
    color: #202020;
  }

  &:active {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #202020;
  }
}

.label-btn {
  cursor: pointer;
  background-color: #8a8a8a;
  border: 1px solid #8a8a8a;
  border-radius: 3px;
  line-height: 1.6;
  text-align-last: center;
  font-size: 0.95rem;
  color: #ffffff;

  label {
    cursor: pointer;
  }

  &:hover {
    border: 1px solid #a1a1a1;
    background-color: #a1a1a1;
    color: #ffffff;
  }

  &:active {
    border: 1px solid #378bfe;
    background-color: #8a8a8a;
    color: #ffffff;
  }
}

.de-active {
  pointer-events: none;
  background-color: #575757;
}

input[type='number']::-webkit-inner-spin-button {
  margin: 0;
}

.tooltip-right {
  background-color: #FDF4CB !important;
  color: #000000 !important;
  font-weight: bold;
  &:after {
    border-right-color: #FDF4CB !important;
  }
}

.tooltip-bottom {
  background-color: #FDF4CB !important;
  color: #000000 !important;
  font-weight: bold;
  &:after {
    border-bottom-color: #FDF4CB !important;
  }
}

.tooltip-top {
  background-color: #FDF4CB !important;
  color: #000000 !important;
  font-weight: bold;
  &:after {
    border-top-color: #FDF4CB !important;
  }
}

.tooltip-left {
  background-color: #FDF4CB !important;
  color: #000000 !important;
  font-weight: bold;
  &:after {
    border-left-color: #FDF4CB !important;
  }
}