.panel {
  position: absolute;
  overflow: hidden;
  text-align: center;
  background-color: rgb(83, 83, 83);
  color: #dddddd;

  border: 1px solid #363636;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);

  &.react-draggable-dragging {
    z-index: 20;
  }

  .inner-handle {
    height: 32px;
    width: 100%;
    background-color:#424242;
    color: #b5b5b5;
    border-bottom:1px solid #393939;
    line-height: 2.3;
    text-align: left;

    .label {
      padding-left: 10px;
      font-size: 16px;
      height: 32px;
      display: inline-block;
      position: absolute;
      line-height: 30px;
    }

    .icon_close {
      float: right;
      margin: 5px;
      width: 24px;
      height: 24px;
      background-color: #7b7b7b;
      -webkit-mask-image: url("../../../../images/icon-close.svg");
      mask-image: url("../../../../images/icon-close.svg");

      -webkit-mask-size: 24px 24px;
      mask-size: 24px 24px;
      cursor:pointer;

      &:hover {
        background-color: #acacac;
      }

      &:active {
        background-color: #ffffff;
      }
    }
  }



  .inner-grid {
    margin: 2px;
  }

  .grid-wrapper {
    width: 100%;
    display: grid;
    grid-row-gap: 2px;
    grid-column-gap: 2px;
  }

  .grid-wrapper .grid-menu {
    /*background-color: orange;*/
    overflow: hidden;
  }

  .grid-wrapper .grid-menu:hover {
    background-color: rgba(72, 72, 72, .3);
    cursor:pointer;
  }

  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    display: none;
  }

  .generator-panel:hover .react-resizable-handle {
    display: block;
  }

  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }

  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }

  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }

  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }

  .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }

  .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }

  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }

  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }

  .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }
}