.title-area {
  background: #414141;
  border-bottom: 1px solid #4b4b4b;
  height: 55px;
  display: inline-block;
  width: 100%;
  text-align: center;

  .title-name {
    display: inline-block;
    font-size: 1.25rem;
    color:#f0f0f0;
    line-height:2.65;
  }

  .title-x-btn {
    display: inline-block;
    float: right;
    margin: 15px 22px 0px 0px;
    border: 0;
    background: none;
  }

  .icon_close {
      width: 24px;
      height: 24px;
      background-color: #7b7b7b;
      -webkit-mask-image: url("../../images/icon-close.svg");
      mask-image: url("../../images/icon-close.svg");

      -webkit-mask-size: 24px 24px;
      mask-size: 24px 24px;
      cursor:pointer;
    
      &:hover {
        background-color: #acacac;
      }

      &:active {
        background-color: #ffffff;
      }
   }
}

