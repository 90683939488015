.tooltip-area {
  margin-left: 5px;
  display: inline-block;

  &.no-question {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
  }
  .question-image {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}