#LinkPagePanel {
  .inner-handle {
    position: relative;
    height: 100%;
    width: 32px;
    display: inline-block;
    margin: 0;
    padding: 0;
    border-bottom: 0;
    border-right: 1px solid #393939;
    float: left;
  }

  .inner-contents {
    position: relative;
    height: 100%;
    width: calc(100% - 35px);
    float: right;
    display: inline-block;

    .inner-list {
      width: calc(100% - 22px);
      height: calc(60% - 22px);
      margin: 5px;
      padding: 5px;
      background-color: #ffffff;
      overflow: auto;
      outline: none;
      border: 1px solid #4b4b4b;
      border-radius: 2px;
      position: relative;

      .drop-zone {
        min-width: 110px;
        width:max-content;
        height: calc(100% - 14px);
        border: 2px dotted #8c8c8c;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 5px;

        .drop-zone-empty {
          width: 100%;
          text-align: center;
          font-size: 10px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.zone-empty {
          border: 2px dotted #8c8c8c;
        }
        &.zone-dragging {
          border: 2px dotted #177cee;
        }
        &.zone-hovered {
          border: 2px dotted #c8c916;
        }

        .link-page-item {
          padding: 5px 2.5px;
          color: #707070;

          .link-page-thumb {
            .add-thumb {
              float: left;
              width: 20px;
              height: 20px;
              display: none;
              cursor: pointer;

              label {
                cursor: pointer;
              }
            }
            .thumb {
              width: 105px;
              height: 70px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              background-color: #e7e7e7;
              cursor: grab;
            }
            .delete-link-page {
              width: 20px;
              height: 20px;
              float: right;
              display: none;
            }

            &:hover {
              .delete-link-page,
              .add-thumb {
                display: block;
                cursor: pointer;
              }
            }
          }

          .link-page-label {
            font-size: 12px;
            width: 100%;
            height: 16px;
            overflow: hidden;
          }
        }
      }
    }

    .input-div {
      .input-contents {
        margin: 5px 0;
      }
      .input-title {
        width: 30%;
      }
      .input-text {
        width: 50%;
        padding: 3px 3px 3px 5px;
        margin: 0 10px;
        background-color: #454545;
        border: 1px solid #676767;
        text-align: left;
        color: #e2e2e2;
        font-size: 12px;
        cursor: pointer;
        float: right;
      }

      .add-btn {
        width: 30%;
        cursor: pointer;
        color: #FFFFFF;
        font-size: 12px;
        text-align: center;
        background-color: #444444;
        border-radius: 5px;
        padding: 3px 6px 1px 6px;
        border: 1px solid #676767;
        margin: 1px 0;
      }
    }
  }
}