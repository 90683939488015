#Loading {

  &.hide {
    display: none;
  }

  .spinner-container {
    width: 640px;
    height: auto;
  }

  .spinner {
    background: url("../../images/loading-book.gif") no-repeat center center;
    background-size: contain;
    width: 450px;
    height: 350px;
    position: relative;
    margin: 0px auto;
    opacity:1;
  }

  .msg {
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: 1.25rem;
    color: #a3a3a3;
    line-height: 2.3;
    vertical-align: bottom;
    position: absolute;
    bottom: 65px;
  }
}