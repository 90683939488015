.input-form-with-label {
  display: block;
  margin: 20px 0;
  width: 100%;
  height: 25px;

  .label {
    display: inline-block;
    font-size: 0.95rem;
    line-height:2.0;
  }

  .input-form {
    display: inline-block;
    float: right;
    /*width: 150px;
    height: 100%;*/
    border-radius: 2px;
    text-align-last: left;

    input {
      width: 200px;
      height: calc( 100% - 2px);
      background-color: #4a4a4a;
      border: 1px solid #666666;
      padding: 6px 6px 6px 12px;
      color: #ffffff;
      font-size: 0.9rem;
    }
  }

  .input-form {
    display: inline-block;
    float: right;
    /*width: 150px;
    height: 100%;*/
    border-radius: 2px;
    text-align-last: left;

    input {
      width: 200px;
      height: calc( 100% - 2px);
      background-color: #4a4a4a;
      border: 1px solid #666666;
      padding: 6px 6px 6px 12px;
      color: #ffffff;
      font-size: 0.9rem;
    }
  }

  .btn_new_upload{
      outline: 0;
      font-size: 0.85rem;
      margin: 0 auto;
      display: block;
      padding: 5px 0px;
      border-radius: 5px;
      cursor: pointer;
      line-height: 1.6;
      width:220px;
  }
}