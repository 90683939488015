#Menu {
  width: 65px;
  position: relative;
  display: inline-block;
  height: 100%;
  background-color: #3e3e3e;
  border-right: 1px solid #383838;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
  text-align: center;
  z-index: 1;

  .menu-container {
    width: 65px;
    text-align: center;
    color: #a2a2a2;
    font-size: .73rem;
  }

  .menu-btn {
    padding: 13px 0 7px 0;
    color: #afafaf;
    font-size: .74rem;
    line-height: 1.7;
    text-align: center;
    cursor: pointer;

    &.selected {
      background-color: #177def;
      color: #ffffff;

      .h-space {
        background-color: #ffffff;
      }
    }

    &:hover {
      background-color: #343434;
      color: #afafaf;

      .h-space {
        background-color: #afafaf;
      }
    }

    &:active {
      background-color: #2a2a2a;
      color: #ffffff;

      .h-space {
        background-color: #ffffff;
      }
    }

    &.de-active {
      pointer-events: none;
      color: #656565;

      .h-space {
        background-color: #656565;
      }
    }
  }

  .h-space {
    margin: 0 0 1px 0;
  }

  .menu_icon {
    display: block;
    width: 65px;
    margin-left: auto;
    margin-right: auto;
    text-align: -webkit-center;
  }

  .menu-btn-container {
    margin: 0;
    top: 0;

    .menu_icon {
      margin-bottom: 2px;
    }

    .icon_home {
      width: 26px;
      height: 26px;
      background-color: #afafaf;
      -webkit-mask-image: url("../../images/icon-home.svg");
      mask-image: url("../../images/icon-home.svg");

      -webkit-mask-size: 26px 26px;
      mask-size: 26px 26px;
    }

    .icon_editor {
      width: 28px;
      height: 28px;
      background-color: #afafaf;
      -webkit-mask-image: url("../../images/icon-edit.svg");
      mask-image: url("../../images/icon-edit.svg");

      -webkit-mask-size: 28px 28px;
      mask-size: 28px 28px;
    }

    .icon_import {
      width: 26px;
      height: 26px;
      background-color: #afafaf;
      -webkit-mask-image: url("../../images/icon-csv.svg");
      mask-image: url("../../images/icon-csv.svg");

      -webkit-mask-size: 26px 26px;
      mask-size: 26px 26px;
    }

    .icon_setting {
      width: 28px;
      height: 28px;
      background-color: #afafaf;
      -webkit-mask-image: url("../../images/icon-setting.svg");
      mask-image: url("../../images/icon-setting.svg");

      -webkit-mask-size: 28px 28px;
      mask-size: 28px 28px;
    }
  }

  .menu-icon-container {
    position: fixed;
    bottom: 0;
    letter-spacing: -0.2pt;
    margin-bottom: 0;

    .menu_icon {
      margin-bottom: 4px;
    }

    .user-icon {
      width: 30px;
      height: 30px;
      margin: 0 auto;
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;

      &.icon1 {
        background-image: url("../../images/user-icon1.png");
      }
      &.icon2 {
        background-image: url("../../images/user-icon2.png");
      }
      &.icon3 {
        background-image: url("../../images/user-icon3.png");
      }
      &.icon4 {
        background-image: url("../../images/user-icon4.png");
      }
      &.icon5 {
        background-image: url("../../images/user-icon5.png");
      }
    }

    .icon_account {
      width: 28px;
      height: 28px;
      background-color: #afafaf;
      -webkit-mask-image: url("../../images/icon-account.svg");
      mask-image: url("../../images/icon-account.svg");

      -webkit-mask-size: 28px 28px;
      mask-size: 28px 28px;
    }

    .icon_save {
      width: 25px;
      height: 25px;
      background-color: #afafaf;
      -webkit-mask-image: url("../../images/icon-save.svg");
      mask-image: url("../../images/icon-save.svg");

      -webkit-mask-size: 25px 25px;
      mask-size: 25px 25px;
    }
  }

  .menu-icon {
    width: 100%;
    height: 10%;
    position: absolute;
    bottom: 0;

    color: #acacac;
    font-size: .85rem;

    .menu-btn {
      margin: 40% 0;
      padding: 10% 0;

      &:hover {
        background-color: #fbbc02;
        color: #515153;
      }

      &:active {
        background-color: #cd9b02;
      }
    }
  }
}