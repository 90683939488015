.account-component-area {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #212224;
  display: block;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.account-content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:90px;


  .account-content-center {
    width: 500px;
    height: auto;
    margin: 0 auto;
    background-color: #323232;
    border-radius: 3px;
    border: 1px solid #3d3d3d;
    padding: 30px 50px 40px 50px;
  }

  .component-column-area {
    width: 100%;
    height: 70%;
    margin: 0px auto;
    display: block;
  }

  .component-save-area {
    width: 100%;
    height: 24%;
    display: flex;
    margin: auto;
  }

  .account-save-btn {
    margin: auto;
    display: block;
    width: 465px;
    height: auto;
    padding:5px 0px;
    margin-top: 15px;
    line-height: 2.0;

    font-weight: normal;
    font-size: 1.05em;
  }
}


.account-btn {
   border: 0;
   background-color: #707070;
   color: #ffffff;
   border-radius: 4px;
   margin: 0px;
   width: 50%;
   padding: 8px 15px;
   font-size: 0.85rem;
   cursor: pointer;
   float: left;
   outline:none;


    &:hover, active, selected {
      background-color: #ffffff;
      color:#333333;
    }
}

.account-btn-save {
   border: 0;
   background-color: #707070;
   color: #ffffff;
   border-radius: 4px;
   margin: 0px;
   width: 98%;
   padding: 0px 15px;
   font-size: 0.95rem;
   line-height: 2.2;
   cursor: pointer;
   float: left;
   outline:none;
       
       &:hover {
          background-color: #1473e6;
          color: #ffffff;
       }

       &:active {
          background-color: #0565d9;
          color: #ffffff;
       }
 }