#RepoPanel {
  width: 100%;
  height: 100%;

  .repo-content-area {
    width: 100%;
    height: 100%;

    .loading-area {
      width: 100%;
      height: 100%;
      z-index: 99999;
      background-color: rgba(75, 75, 75, 0.5);
      top: inherit;
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      left: 0;

      .loading-spinner {
        width: 200px;
        height: 200px;
        background: url("../../../../../images/loading-book.gif") no-repeat center center;
        opacity: .5;
        background-size: contain;
      }
    }

    .finder-sort-area {
      width: 100%;
      height: 9%;
      margin-top: 6px;

      .type-select-area {
        width: 50%;
        height: 100%;
        display: flex;
        float: right;
        justify-content: flex-end;
        align-items: center;

        .type-select-title-name {
          display: inline-block;
          font-size: 0.85rem;
          font-weight: normal;
          color: #afafaf;
          padding: 0 10px 0 0;
        }

        .type-select-select-area {
          display: inline-block;
        }

        .type-select-select-tag {
          padding: 3px 20px 3px 4px;
          margin: 0 18px 0 0;

          font-size: 0.85rem;

          background-color: #4b4b4b;
          border: 1px solid #676767;
          border-radius: 3px;
          color: #ffffff;

          width: 130px;
        }
      }
    }

    .main-content-area {
      width: 95%;
      height: 76%;
      margin: 0 auto;

      .content-center-area {
        width: 100%;
        height: 100%;

        .column-title-area {
          width: 100%;
          height: 6%;
          background-color: #424242;
          border: 1px solid #383838;

          .column-title-name {
            display: inline-block;
            width: 20%;
            height: 100%;

            color: #f0f0f0;
            font-size: 0.85rem;
            line-height: 2.2;
          }
        }

        .column-order-area {
          width: 100%;
          height: 30px;
          background-color: #474747;
          border-left: 1px solid #383838;
          border-right: 1px solid #383838;

          .order-data-cell {
            display: inline-block;
            width: 20%;
            height: 100%;
            float: left;
          }

          .order-date-select-tag {
            background-color: #4b4b4b;
            border: 1px solid #676767;
            border-radius: 0;
            color: #ffffff;

            width: 99%;
            padding: 4px 6px 7px 6px;
            margin-right: 1px;
          }
        }

        .column-content-area {
          display: inline-block;
          width: 100%;
          height: 400px;
          background-color: #ffffff;
          overflow-y: scroll;
          border: 1px solid #383838;
          padding: 12px 0;

          .column-contents-block {
            display: inline-block;
            width: 100%;
            /*height: 7.5%;*/
            margin: 6px 0;

            .repo-column-text {
              display: inline-block;
              width: 20%;
              color: #333333;
              font-size: 0.85rem;
              user-select: all;

              .clipboard-copy {
                margin: 4px;
                padding: 4px;
                font-size: 7px;
              }

              .repo-image {
                height: auto;
                width: auto;
                max-height: 45px;
                max-width: 100px;
                outline: 1px solid #676767;
              }
            }

            .repo-column-buttons-area {
              display: inline-block;
              width: 20%;
              font-size: 10px;

              .delete-button {
                padding: 4px 15px;
                margin: 0 0 0 3px;
              }
            }
          }
        }
      }

      .repo-column-button {
        margin: 0 4px 0 0;
        padding: 4px 15px;

        display: inline-block;
        font-size: 12px;
        color: #e3e3e3;
        border-radius: 4px;
        background-color: #626262;
        border: 1px solid #626262;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #fafafa;
          border: 1px solid #8a8a8a;
          background-color: #8a8a8a;
        }
      }
    }

    .repo-button-area {
      width: 100%;
      height: 5%;
      margin-top: 10px;

      .repo-file-button {
        display: block;
        float: right;
        height: 32px;
        margin: 0 6px;

        font-size: 14px;
        color: #e3e3e3;
        text-align: center;
        border-radius: 4px;
        background-color: #4b4b4b;
        border: 1px solid #828282;
        cursor: pointer;

        label {
          line-height: 30px;
          padding: 10px 34px;
          cursor: inherit;
        }

        &:hover,
        &.selected {
          color: #fafafa;
          border: 1px solid #8a8a8a;
          background-color: #8a8a8a;
        }
      }
    }
  }
}